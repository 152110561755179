import state from './moduleBrandTaxManagementState.js'
import mutations from './moduleBrandTaxManagementMutations.js'
import actions from './moduleBrandTaxManagementActions.js'
import getters from './moduleBrandTaxManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

