import axios from "@/axios.js";

export default {
  fetchBrandTaxes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("brand-taxes")
        .then(({ data: response }) => {
          commit("SET_BRAND_TAX", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchBrandTax(context, brandTaxId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`brand-taxes/${brandTaxId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  storeBrandTax(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("brand-taxes", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateBrandTax(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`brand-taxes/${payload.id}`, payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  destroyBrandTax({ commit }, brandTaxId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`brand-taxes/${brandTaxId}`)
        .then(response => {
          commit("REMOVE_RECORD", brandTaxId);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
